var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mx-2"},[_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"rounded-lg mx-1",attrs:{"outlined":"","prepend-inner-icon":"search","label":"Search","single-line":"","hide-details":"","color":"#239FAB","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"white--text rounded-lg mx-1",attrs:{"color":"#519043"},on:{"click":function($event){return _vm.add()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus-box-outline ")]),_vm._v(" Add New ")],1)],1)],1),_c('v-card',{staticClass:"ma-5 dt-container",attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"items-per-page":10,"search":_vm.search,"options":_vm.options,"loading":_vm.loading,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event},"pagination":_vm.pagination},scopedSlots:_vm._u([{key:"item.fy_from",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.fy_from))+" ")]}},{key:"item.fy_to",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.fy_to))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"x-small":"","color":item.status == 1 ? 'green' : 'red',"text-color":"white"}},[_vm._v(" "+_vm._s(item.status == 1 ? "Active" : "Inactive")+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-1",attrs:{"x-small":"","color":"grey","outlined":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"size":"14"}},[_vm._v("mdi-pencil-outline")]),_vm._v(" Update ")],1),_c('v-btn',{staticClass:"mx-1",attrs:{"x-small":"","color":"grey","outlined":""},on:{"click":function($event){return _vm.viewItem(item)}}},[_c('v-icon',{attrs:{"size":"14"}},[_vm._v("mdi-eye")]),_vm._v(" View ")],1)]}}],null,true)})],1),_c('v-row',{staticClass:"mb-2 mx-5",attrs:{"align":"center"}},[_c('v-col',{staticClass:"mr-auto text-truncate",attrs:{"cols":"auto","no-gutters":""}},[_vm._v(" Showing "+_vm._s(_vm.paginationData.pageStart + 1)+" to "+_vm._s(_vm.paginationData.pageStop)+" of "+_vm._s(_vm.paginationData.itemsLength)+" entries ")]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-pagination',{staticClass:"rounded-lg",attrs:{"total-visible":7,"color":"#519043","length":_vm.paginationData.pageCount},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}})],1)],1),_c('ViewSalaryGrade',{attrs:{"data":_vm.viewData}}),_c('FiscalBudgetYearDialog',{attrs:{"data":_vm.updateData,"action":_vm.action}}),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialogConfirmDelete),callback:function ($$v) {_vm.dialogConfirmDelete=$$v},expression:"dialogConfirmDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Confirmation ")]),_c('v-card-text',{staticStyle:{"font-size":"17px"}},[_vm._v(" Are you sure you want to delete this item ? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"teal darken-3","outlined":""},on:{"click":function($event){_vm.dialogConfirmDelete = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"white--text",attrs:{"color":"teal darken-3"},on:{"click":function($event){_vm.confirmDelete();
            _vm.dialogConfirmDelete = false;}}},[_vm._v(" Confirm ")])],1)],1)],1),_c('fade-away-message-component',{attrs:{"displayType":"variation2","message":_vm.fadeAwayMessage.message,"header":_vm.fadeAwayMessage.header,"top":_vm.fadeAwayMessage.top,"type":_vm.fadeAwayMessage.type},model:{value:(_vm.fadeAwayMessage.show),callback:function ($$v) {_vm.$set(_vm.fadeAwayMessage, "show", $$v)},expression:"fadeAwayMessage.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }